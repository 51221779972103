body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.overflowelipse
{
  overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
}
.ag-root-wrapper-body{
  height: 870px !important;
}

.ag-icon-menu{
  color:white !important;
}


.decoded-access-code-results{
  text-align:left;
  margin-left:10%;
  margin-top:25px;
}

.decoded-access-code-results > .form-group{
  margin-top:10px;
}
.future-codes {
  background-color: #e6cf09;
  color:black;
}

.access-code-modal > .modal-dialog{
  max-width: 1020px;

}

.decode-results-label{
  margin-right:20px;
  margin-left:200px;
  font-size:16px;
  width:120px;
}

.decode-results{
  width:500px;
  text-align:left;
}

.decode-container{
  text-align:center;
}

.decode-label{
  margin-right:7px;
  margin-left:10px;
  font-size:16px;
  width:100px;
}

.lock-info-header, .ag-header-row{
  background-color: #1f5c73;
  color:white;

}
.ag-header-cell{
  color:white;
}

.ag-cell{
  padding-top: 4px;
  padding-bottom:4px;
}

.ag-row{
  height:53px !important;
}

.hideCodeBtn {
  margin-left: 10px;
  margin-right: 5px;
  float:right;
}

.btn {
  margin:5px
}

.ag-cell {
  user-select: all !important; 
}


